<template>
  <v-app class="render">
    <!-- <Navbar v-if="user && user.name" :user="user" style="z-index: 99999" /> -->
    <Sidebar
      v-if="user && user.name"
      :user="user"
      @showModal="flagModalInviteCust = true"
    />
    <v-main
      dark
      class="justify-center"
      :style="{
        background: route ? 'white' : $vuetify.theme.themes[theme].background,
      }"
    >
      <v-overlay :value="spinnerShow" style="z-index: 999" absolute>
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
          z-index="999"
        ></v-progress-circular>
      </v-overlay>
      <snackbar :nav="false" />

      <router-view></router-view>
      <ModalUnregisteredCustomer
        v-if="flagModalInviteCust"
        :show="flagModalInviteCust"
        :width="$vuetify.breakpoint.width < 1000 ? '70%' : '50%'"
        title="Invite To"
        :emailList="emailList"
        @clearList="clearList"
        @addNewUnregisterEmail="addNewUnregisterEmail"
        @removeUnregisterEmail="removeUnregisterEmail"
      >
        <template>
          <v-row justify="start" class="py-2" align="center">
            <v-col cols="auto" class="py-2">
              <v-row style="gap: 2px">
                <v-col cols="auto">
                  <v-btn
                    small
                    dense
                    block
                    class="rounded-0"
                    @click="linkTab = 0"
                    :class="linkTab === 0 ? 'primary' : ''"
                    >General Link</v-btn
                  >
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    @click="linkTab = 1"
                    small
                    dense
                    block
                    class="rounded-0"
                    :class="linkTab === 1 ? 'primary' : ''"
                    >Auctions Link</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col v-if="linkTab === 1" cols="3">
                  <v-autocomplete
                    v-model="auctionCode"
                    :items="auctionsData"
                    dense
                    solo
                    outlined
                    background-color="dark_blue"
                    hide-details
                    class="pa-0 ma-0 elevation-5 text-center elevation-24 rounded-md"
                    item-text="codeName"
                    item-value="code"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col :cols="linkTab === 1 ? 8 : 11">
                  <v-text-field
                    hide-details
                    type="text"
                    outlined
                    clearable
                    label="Link to invitation"
                    class="ma-0 rounded-5 px-1"
                    style="font-weight: 300 !important; font-size: 1.2rem"
                    dense
                    :value="inviteLink"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn block color="primary" @click="copyLink">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:actions>
          <v-row class="ma-0 pa-0" justify="space-around">
            <v-col class="px-2" cols="6">
              <v-btn
                class="elevation-5 primary"
                color="white"
                style="border: none"
                text
                light
                @click="sendInvitations"
                block
              >
                Send Invitations
              </v-btn>
            </v-col>
            <v-col class="px-2" cols="6">
              <v-btn
                class="elevation-5"
                color="primary"
                style="border: none"
                text
                light
                @click="flagModalInviteCust = false"
                block
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </ModalUnregisteredCustomer>
    </v-main>
    <Footer />
  </v-app>
</template>

<style lang="scss"></style>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Navbar from "@/components/General/Navbar.vue";
import Sidebar from "@/components/General/Sidebar2.vue";
import Footer from "./components/Footer/index.vue";

import Snackbar from "@/components/General/Snackbar";
import ModalUnregisteredCustomer from "@/components/InventoryQtySumm/ModalUnregisteredCustomer.vue";
import { get_visible_entities } from "./helpers/api/indexv2.js";
import { validate } from "vee-validate";
import axios from "axios";

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Nereus - Asset Management",
    // all titles will be injected into this template
    titleTemplate: "%s | Nereus - Asset Management",
  },
  components: {
    Footer,
    Sidebar,
    Navbar,

    Snackbar,
    ModalUnregisteredCustomer,
  },
  async created() {
    axios.interceptors.request.use(async function (config) {
      if (!config.url.includes("admin")) {
        const token = localStorage.getItem("access_token");

        config.headers.authorization = "";
        config.headers.authorization = `${token}`;
      }
      return config;
    });
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error && error.response && error.response.status === 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          localStorage.removeItem("header");
          if (this.$route.path !== "/login") this.$router.push("/login");
        }
        return Promise.reject(error);
      }
    );
  },

  data() {
    return {
      timer: null,
      flagModalInviteCust: false,
      emailList: [],
      inviteLinkToken: null,
      linkTab: 0,
      auctionCode: null,
    };
  },
  watch: {
    async flagModalInviteCust() {
      if (this.flagModalInviteCust) {
        const data = await this.generateInviteLink();
        this.inviteLinkToken = data.uniqueToken;
      }
    },
    async linkTab() {
      if (this.linkTab === 1) {
        await this.getAuctions({ offset: 0 });
        this.auctionCode = this.listAuctions.auctions[0].AuctionCode;
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      spinnerShow: (state) => state.spinnerShow,
      listAuctions: (state) => state.moduleAuctions.listAuctions,
    }),
    ...mapGetters({
      getUserType: "getUserType",
    }),

    auctionsData() {
      return this.listAuctions.auctions
        ? this.listAuctions.auctions.map((x) => {
            return {
              codeName: `${x.name} | ${x.AuctionCode}`,
              code: x.AuctionCode,
            };
          })
        : [];
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    route() {
      return this.$route.meta.light;
    },
    inviteLink() {
      let enviroment =
        process.env.VUE_APP_ENV == "dev" ? "development" : process.env.VUE_APP_ENV;

      // const code = this.linkTab === 1 ? `&auctionCode=${this.auctionCode}` : "";
      // const link = `http://localhost:9001/customer-app/signup?inviteId=${this.inviteLinkToken}`;
      const link =
        enviroment == "production"
          ? `https://customer.nereus.io/customer-app/signup${this.inviteLinkShort}`
          : `https://spa-root-${enviroment}-vjuqlq6gsq-uw.a.run.app/customer-app/signup${this.inviteLinkShort}`;

      return link;
    },

    inviteLinkShort() {
      const code = this.linkTab === 1 ? `&auctionCode=${this.auctionCode}` : "";

      return `/invite?inviteId=${this.inviteLinkToken}${code}`;
    },
  },
  mounted: function () {
    // Ping backend every 5 minutes to see if we get a 401
    // 300000
    this.timer = setInterval(() => {
      try {
        get_visible_entities();
      } catch (error) {
        //
      }
    }, 3600000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions({
      sendInviteEmails: "sendInviteEmails",
      generateInviteLink: "generateInviteLink",
      getAuctions: "moduleAuctions/getAuctions",
    }),

    copyLink() {
      navigator.clipboard.writeText(this.inviteLink);
    },
    clearList() {
      this.emailList = [];
    },
    async pasteEmailListField(text) {
      let res = text;

      res = res.replaceAll(" ", "");
      res = res.replaceAll(";", ",");
      res = res.replaceAll(",", " ").split(/\r\n|\r|\n| /, -1);

      res.forEach(async (x) => {
        if (x.length) {
          let { valid } = await validate(x, "email");
          if (valid) {
            this.emailList.push({
              primary_email: x,
            });
          }
        }
      });
    },
    addNewUnregisterEmail(newEmail) {
      this.pasteEmailListField(newEmail);
    },
    removeUnregisterEmail(index) {
      this.emailList.splice(index, 1);
    },
    sendInvitations() {
      let data = {
        customers: this.emailList,
        url: this.inviteLinkShort,
      };
      this.sendInviteEmails(data);
      this.flagModalInviteCust = false;
      this.emailList = [];
    },
  },
};
</script>

<style>
body {
  background-color: #1e1e1e;
}
</style>

<style scoped>
/* .small-nav-letter {
  position: absolute;
  left: 0;
  top: 14px;
  width: 100%;
  height: 100%;
  text-align: center;
} */
</style>

<style lang="scss">
/* @import "scss/main-2.scss"; */
@import "scss/main-2.scss";
.v-main__wrap {
  display: flex;
  /* flex: initial !important; */
}
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
/* app.scss */
/* had to define the selector properly so it would work */

.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background: var(--v-primary-base);
}
.theme--light.v-application ::-webkit-scrollbar {
  width: 13px;
}

.theme--light.v-application ::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.theme--light.v-application ::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.theme--light.v-application ::-webkit-scrollbar-thumb:hover {
  background: black;
}

.theme--dark.v-application ::-webkit-scrollbar {
  width: 13px;
}

.theme--dark.v-application ::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.theme--dark.v-application ::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.theme--dark.v-application ::-webkit-scrollbar-thumb:hover {
  background: white;
}

.testgh {
  background: blue;
}
</style>
